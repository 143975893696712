import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Form from '../components/Form';
import '../styles/layout.css';
import audrey from '../../static/audrey-wide.png';
import simultaantolken from '../../static/simultaantolken.jpg';
import verbindingstolken from '../../static/verbindingstolken.jpg';
import badge from '../../static/kudo-interpreter-badge.png';
import stempel from '../../static/stempel.jpg';
import sdl from '../../static/SDL.png';

export default () => (
  <div>
    <Helmet>
      <title>B&D – Audrey Fromont</title>
      <meta
        name="description"
        content="Beëdigd vertaler, beëdigd tolk, conferentietolk."
      />
      <meta name="robots" content="index, follow" />
      <meta
        name="keywords"
        content="RSI, Kudo, Interprefy, Interactio, Voiceboxer, Hopin, Zoom, Qua Qua
vertaler + tandheelkunde, SDL Trados, Translator + dentistry, dental translator, Dutch"
      />
    </Helmet>
    <Layout>
      <div className="columns">
        <div className="column is-full">
          <img src={audrey} />
        </div>
      </div>
      <div className="container is-fluid">
        <hr />
        <nav className="level">
          <h3
            style={{ fontFamily: 'Dancing Script' }}
            className="introItem level-item has-text-centered is-size-6-mobile"
          >
            Traductrice jurée- interprète jurée - interprète de conférence
          </h3>
        </nav>
        <hr />
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">Interprétation simultanée</h3>
            <p className="is-size-7-mobile">
              <strong>
                RSI: Kudo, Interprefy, Interactio, Voiceboxer, Hopin, Zoom, Qua
                Qua, …
              </strong>
            </p>
            <img className="mb-3" src={badge} alt="" width="100" />
            <p className="is-size-7-mobile">
              L’interprétation simultanée s’effectue oralement, à l’aide du
              matériel adéquat, comme des microphones, écouteurs et
              éventuellement une cabine. Ainsi, il est possible de suivre une
              conversation ou un exposé simultanément dans la langue originale
              et en traduction. N’hésitez pas à faire appel à mes services pour
              la traduction simultanée en néerlandais et anglais. Voilà près de
              20 ans que des bureaux d’interprétation en Belgique et à
              l’étranger me font confiance.
            </p>
            <img src={simultaantolken} />
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">Interprète de liaison</h3>
            <p className="is-size-7-mobile">
              Ce type d’interprétation ne nécessite aucun matériel technique. En
              tant qu’interprète (jurée), je traduis oralement lors d’examen de
              conduite, ainsi que lors d’auditions par la police, l’inspection
              sociale, le service des tutelles, etc. Dans ce cadre, vous pouvez
              me contacter pour le néerlandais, le français, l’anglais,
              l’espagnol et le chinois (mandarin).
            </p>
            <img src={verbindingstolken} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">
              Traductions assermentées (par écrit)
            </h3>
            <p className="is-size-7-mobile">
              Vous avez été prié de communiquer une traduction assermentée? A
              l’aide du formulaire de contact, adressez-moi votre demande pour
              le néerlandais, l’anglais, le français et l’espagnol. Je suis
              jurée près les tribunaux d’Anvers, Bruxelles, Malines, Louvain,
              Furnes et Gand. Si nécessaire, ma signature peut donc être
              légalisée dans toutes ces villes. A l’étranger, une traduction
              assermentée doit parfois être dotée d’une apostille. Cliquez
              <a className="is-lowercase" href="http://diplomatie.belgium.be">
                {' '}
                ici{' '}
              </a>{' '}
              pour des informations plus détaillées.
            </p>
            <img src={stempel} />
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">
              Traductions en néerlandais de textes généraux
            </h3>
            <p className="is-size-7-mobile">
              <strong style={{ color: 'white' }}>
                Traductions en néerlandais de textes généraux, commerciaux,
                administratifs, notariés, juridiques, financiers. Les domaines
                du tourisme, de la mode, du design et de la médicine dentaire
                ont également été traités dans le passé.
              </strong>
            </p>
            <p className="is-size-7-mobile">
              Peu importe que votre texte soit un document volumineux ou un
              simple paragraphe. Posez votre question à l’aide du formulaire de
              contact, vous recevrez le tarif et le délai de livraison au plus
              vite.
            </p>
            <p className="is-size-7-mobile">
              J’assure moi-même les traductions en néerlandais (ma langue
              maternelle). Les traductions en d’autres langues sont révisées par
              des collègues ayant pour langue maternelle l’anglais, l’espagnol
              ou le français.
            </p>
          </div>
        </div>
        <hr />
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">Affiliée à</h3>
            <p className="is-size-7-mobile">
              Lextra Lingua- Association professionnelle traducteurs/interprètes
              jurés
            </p>
            <p className="is-size-7-mobile">
              Centre for Translation Studies (CETRA)
            </p>
            <p className="is-size-7-mobile">
              Belgian Association of Anglicists in Higher Education (BAAHE)
            </p>
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">Diplômes</h3>
            <p className="is-size-7-mobile">
              Master en traduction. Université d’Anvers
            </p>
            <p className="is-size-7-mobile">
              Master en interprétation. Vlekho Bruxelles
            </p>
            <p className="is-size-7-mobile">
              Doctorat en linguistique aplliquée: traductologie. Katholieke
              Universiteit Leuven (Louvain)
            </p>
          </div>
        </div>
        <hr />
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">CONTACT</h3>
            <p className="is-size-7-mobile">Astene (Deinze)</p>
            <p className="is-size-7-mobile">audrey.fromont@gmail.com</p>
            <p className="is-size-7-mobile">+32(0)475 29 20 28</p>
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">Formulaire de contact</h3>
            <Form
              firstLabel="Nom:"
              secondLabel="Email:"
              thirdLabel="Message:"
              btnLabel="Envoyer"
              firstOption="- un particulier"
              secondOption="- une entreprise / association / agence "
              thirdOption="- un service de police"
              fourthOption="- un collaborateur d'université"
              selectLabel="Vous êtes:"
            />
          </div>
        </div>
      </div>
    </Layout>
  </div>
);
